define('frontend/instance-initializers/setup-routes', ['exports', 'ember', 'torii/bootstrap/routing', 'torii/configuration', 'torii/compat/get-router-instance', 'torii/compat/get-router-lib', 'torii/router-dsl-ext'], function (exports, _ember, _toriiBootstrapRouting, _toriiConfiguration, _toriiCompatGetRouterInstance, _toriiCompatGetRouterLib, _toriiRouterDslExt) {
  exports['default'] = {
    name: 'torii-setup-routes',
    initialize: function initialize(applicationInstance /*, registry */) {
      var configuration = (0, _toriiConfiguration.getConfiguration)();

      if (!configuration.sessionServiceName) {
        return;
      }

      var router = (0, _toriiCompatGetRouterInstance['default'])(applicationInstance);
      var setupRoutes = function setupRoutes() {
        var routerLib = (0, _toriiCompatGetRouterLib['default'])(router);
        var authenticatedRoutes = routerLib.authenticatedRoutes;
        var hasAuthenticatedRoutes = !_ember['default'].isEmpty(authenticatedRoutes);
        if (hasAuthenticatedRoutes) {
          (0, _toriiBootstrapRouting['default'])(applicationInstance, authenticatedRoutes);
        }
        router.off('willTransition', setupRoutes);
      };
      router.on('willTransition', setupRoutes);
    }
  };
});
/* eslint-disable ember/new-module-imports */