define('frontend/routes/publications/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),
    model: function model() {
      return _ember['default'].RSVP.hash({
        news_message: this.store.find('message', 'NEWS'),
        alert_message: this.store.find('message', 'ALERT')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controllerFor("application").set('messages', model);

      if (this.get('session.data.authenticated.can_biblreview')) {
        this.controllerFor("application").set('viewMode', 'advanced');
      }
      controller.fetchUserdataLoop(60000);
      //  return this._super();
    }
  });
});