define('frontend/controllers/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    application: _ember['default'].inject.controller(),
    errorMessage: false,
    loginDisabled: false,

    actions: {
      authenticateOAuth2: function authenticateOAuth2() {
        var _this = this;

        this.set('loginDisabled', true);
        this.get('session').authenticate('authenticator:torii', 'gub')
        // Fix me: Error handling should be more structured.
        ['catch'](function (error) {
          // When the error response is initated by backend, the error object is an array, and the status can be found in the first element of the array.
          if (error.errors && error.errors[0].status === '403') {
            _this.set('errorMessage', _this.get('i18n').t('login.loginNotAllowed'));
          } else {
            if (error.errors && error.errors[0].status === '401') {
              _this.set('errorMessage', _this.get('i18n').t('login.loginGeneralError'));
            } else {
              // When the error response is initiated by the frontend (torii), e.g. when the login window is closed by the user, the error object is a string.
              _this.set('errorMessage', _this.get('i18n').t('login.loginExternalError'));
            }
          }
        })['finally'](function () {
          _this.set('loginDisabled', false);
        });
      }
    }
  });
});