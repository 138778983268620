define('frontend/routes/publications/dashboard/manage/start', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.manage.start.title');
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        news_message: this.store.find('message', 'NEWS'),
        alert_message: this.store.find('message', 'ALERT')
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controller.set('messages', model);
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('application').set('currentList', transition.targetName);
      this.controllerFor('application').set('publication_id_error', null);
      this.controllerFor('publications.dashboard.manage.start').set('publication_id', null);
    }
  });
});