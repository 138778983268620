define('frontend/authenticators/torii', ['exports', 'ember', 'ember-simple-auth/authenticators/torii', 'frontend/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsTorii, _frontendConfigEnvironment) {
  exports['default'] = _emberSimpleAuthAuthenticatorsTorii['default'].extend({
    i18n: _ember['default'].inject.service(),
    torii: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),

    authenticate: function authenticate() {
      var i18n = this.get('i18n');
      var ajax = this.get('ajax');
      var tokenExchangeUri = _frontendConfigEnvironment['default'].torii.providers['gub-oauth2'].tokenExchangeUri;

      return this._super.apply(this, arguments).then(function (data) {
        return ajax.request(tokenExchangeUri, {
          type: 'POST',
          crossDomain: true,
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            code: data.authorizationCode
          })
        }).then(function (response) {
          return {
            authenticated: true,
            token: response.access_token,
            username: response.user.username,
            name: response.user.name,
            userid: response.user.id,
            can_delete_published: _ember['default'].$.inArray('delete_published', response.user.role.rights) !== -1,
            can_biblreview: _ember['default'].$.inArray('biblreview', response.user.role.rights) !== -1,
            can_administrate: _ember['default'].$.inArray('administrate', response.user.role.rights) !== -1,
            provider: data.provider
          };
        });
      });
    }

  });
});