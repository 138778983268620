define('frontend/torii-providers/gub', ['exports', 'torii/providers/oauth2-code', 'torii/configuration', 'frontend/config/environment'], function (exports, _toriiProvidersOauth2Code, _toriiConfiguration, _frontendConfigEnvironment) {
  exports['default'] = _toriiProvidersOauth2Code['default'].extend({
    name: 'gub-oauth2',
    baseUrl: _frontendConfigEnvironment['default'].APP['gub-oauth2'].authorizeUri,
    responseParams: ['code', 'state'],

    redirectUri: (0, _toriiConfiguration.configurable)('redirectUri', function () {
      // A hack that allows redirectUri to be configurable
      // but default to the superclass
      return this._super();
    }),

    fetch: function fetch(data) {
      var _this = this;

      return Ember.$.ajax({
        type: 'GET',
        url: _frontendConfigEnvironment['default'].APP.authenticationBaseURL + '/' + data.token
      }).then(function (response) {
        return {
          authenticated: true,
          token: response.access_token,
          username: response.user.username,
          name: response.user.name,
          userid: response.user.id,
          can_delete_published: Ember.$.inArray('delete_published', response.user.role.rights) !== -1,
          can_biblreview: Ember.$.inArray('biblreview', response.user.role.rights) !== -1,
          can_administrate: Ember.$.inArray('administrate', response.user.role.rights) !== -1,
          provider: data.provider // Does not seems to be required??
        };
      })['catch'](function (error) {
        //TOODO: HOw to provide user feedback, seems like this is caught by adapter if thrown!?
        _this.session.invalidate();
      });
    }
  });
});