define('frontend/routes/publications/dashboard/review', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.review.title');
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('application').set('currentList', transition.targetName);
    },
    model: function model() {
      return this.store.find("review_publication");
    },
    actions: {
      editItem: function editItem(item, params) {
        //			console.log("transitionTo publication.show.edit", item, params);
        this.transitionTo('publications.dashboard.manage.show.edit', item.id, { queryParams: params });
      }
    }
  });
});