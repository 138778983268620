define('frontend/routes/publications/dashboard/biblreview', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.biblreview.title_page');
    },
    queryParams: {
      pubyear: { refreshModel: true },
      page: { refreshModel: true },
      pubtype: { refreshModel: true },
      faculty: { refreshModel: true },
      only_delayed: { refreshModel: true }
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('application').set('currentList', transition.targetName);
    },
    model: function model(params) {
      if (!params.page) {
        params.page = 1;
      }
      return _ember['default'].RSVP.hash({
        publicationList: this.store.find("biblreview_publication", params)
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('model', models.publicationList);
    },
    actions: {
      editItem: function editItem(item, params) {
        this.transitionTo('publications.dashboard.manage.show.edit', item.id, { queryParams: params });
      }
    }
  });
});